'use strict';

export default class UserTable {
  constructor() {
    var tableInstance;
    this.name = 'user-table';
    console.log('%s module', this.name.toLowerCase());
    function renderPageNumber() {
      // console.log(tableInstance);
      if(tableInstance) {
        $('#usertotalpage').text(tableInstance.page.info().recordsTotal);
        $('#userpage').text(' (trang '+(tableInstance.page.info().page+1) + '/' + tableInstance.page.info().pages+')');
      }
    }
    var table = $('#user_table');
    table.on('init.dt', function (e, setting) {
      // console.log(e, setting);
      setTimeout(function() {
        renderPageNumber();
      }, 1000);
    
    }).on('page.dt length.dt', function(){
      renderPageNumber();      
    });
    tableInstance = table.DataTable(
      {
        "scrollX": true,
        'oLanguage': {
          'sLengthMenu': 'Hiển thị _MENU_ trong tổng số <span id="usertotalpage"></span><span id="userpage"></span>'
        }
      }
    );
    // $('#user_table').DataTable({
    //   "autoWidth": false,
    //   "scrollX": true
    // });
  }
}
