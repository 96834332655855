// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Link from '../_modules/link/link';
import formlogin from '../_modules/formlogin/formlogin';
import formregister from '../_modules/formregister/formregister';
import 'bootstrap';
import 'datatables.net-bs4';
// import * as bootstrapValidate from 'bootstrap-validate';
// window.bootstrapValidate = bootstrapValidate;

import UserTable from '../_modules/user-table/user-table'
import EstateTable from '../_modules/estate-table/estate-table'
import scrolltop from '../_modules/scrolltop/scrolltop'

$(window).scroll(function () {
  if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
    $('#return-to-top').fadeIn(200);    // Fade in the arrow
  } else {
    $('#return-to-top').fadeOut(200);   // Else fade out the arrow
  }
});
$('#return-to-top').click(function () {      // When arrow is clicked
  $('body,html').animate({
    scrollTop: 0                       // Scroll to top of body
  }, 500);
});

$(() => {
  new Link(); // Activate Link modules logic
  new UserTable();
  new EstateTable();
  new formlogin();
  new formregister();
  new scrolltop();
  // console.log('Wexlcome to Yeogurt!');
});
