'use strict';

export default class EstateTable {
  constructor() {
    var tableInstance;
    this.name = 'estate-table';
    console.log('%s module', this.name.toLowerCase());
    // var estate_table = $('#estate_table').DataTable();
    // var info = estate_table.page.info();
    // table.destroy();
    function renderPageNumber() {
      // console.log(tableInstance);
      if(tableInstance) {
        $('#estatetotalpage').text(tableInstance.page.info().recordsTotal);
        $('#estatepage').text(' (trang '+(tableInstance.page.info().page+1) + '/' + tableInstance.page.info().pages+')');
      }
    }
    var table = $('#estate_table');
    table.on('init.dt', function (e, setting) {
      // console.log(e, setting);
      setTimeout(function() {
        renderPageNumber();
      }, 1000);

    }).on('page.dt length.dt', function(){
      renderPageNumber();
    });
    tableInstance = table.DataTable(
      {
        'scrollX': true,
        // "columnDefs": [
        //   {
        //     "targets": [5],
        //     "visible": false,
        //   },
        //   {
        //     "targets": [6],
        //     "visible": false
        //   },
        //   {
        //     "targets": [7],
        //     "visible": false
        //   },
        //   {
        //     "targets": [8],
        //     "visible": false
        //   }
        // ],
        scrollCollapse : true,
        'oLanguage': {
          'sLengthMenu': 'Hiển thị _MENU_ trong tổng số <span id="estatetotalpage"></span><span id="estatepage"></span>'
        }
      }
    );
    // console.log(table.page.info());

    // console.log(estate_table);
  }
}
