'use strict';

export default class Formlogin {
  constructor() {
    this.name = 'formlogin';
    console.log('%s module', this.name.toLowerCase());
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $('#inputPassword');
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });

    // bootstrapValidate('#inputEmail3', 'email: Vui lòng nhập đúng Email!|required: Vui lòng không bỏ trống thông tin|numeric', function (isValid) {
    //   if (isValid) {
    //     // alert('Element is valid');
    //   } else {
    //     // alert('Element is invalid');
    //   }
    // });

    $('#inputEmail3').keypress(function () {
      $('#inputEmail3').removeClass('is-invalid');
    })

    $('#loginbutton').click(function () {
      var ep_emailval = $('#inputEmail3').val();
      var intRegex = /[0-9 -()+]+$/;

      if (intRegex.test(ep_emailval)) {
        // console.log("is phone");
        if ((ep_emailval.length < 9) || (!intRegex.test(ep_emailval))) {
          // alert('Please enter a valid phone number.');
          $('#inputEmail3').addClass('is-invalid');
          $('#inputEmail3').siblings().text('Please input a valid phone number');          
          return false;
        }

      }
      else {
        var eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        // console.log("is email");
        if (eml.test(ep_emailval) == false) {
          $('#inputEmail3').addClass('is-invalid');
          $('#inputEmail3').siblings().text('Please input a valid email');
          // alert("Please enter valid email address.");
          // $("#<%=txtEmail.ClientID %>").focus();
          return false;
        }
      }
    });
  }
}
