'use strict';

export default class Formregister {
  constructor() {
    this.name = 'formregister';
    this.match = false;
    // console.log('%s module', this.name.toLowerCase());
    $('#inputEmail').keypress(function () {
      $('#inputEmail').removeClass('is-invalid');
    })
    $('#inputPasswordAgain').keypress(function () {
      $('#inputPasswordAgain').removeClass('is-invalid');
    })
    // bootstrapValidate('#inputPasswordAgain', 'matches:#inputPassword:Your passwords should match')
    // bootstrapValidate('#inputPasswordAgain', 'matches:#inputPassword:Your passwords should match')
    $('#registerbutton').click(function () {
      var ep_emailval = $('#inputEmail').val();
      var pass = $('#inputPassword').val();
      var passagain = $('#inputPasswordAgain').val();
      var intRegex = /[0-9 -()+]+$/;

      if (intRegex.test(ep_emailval)) {
        // console.log("is phone");
        if ((ep_emailval.length < 9) || (!intRegex.test(ep_emailval))) {
          // alert('Please enter a valid phone number.');
          $('#inputEmail').addClass('is-invalid');
          $('#inputEmail').siblings().text('Please input a valid phone number');          
          return false;
        }

      }
      else {
        var eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        // console.log("is email");
        if (eml.test(ep_emailval) == false) {
          $('#inputEmail').addClass('is-invalid');
          $('#inputEmail').siblings().text('Please input a valid email');
          // alert("Please enter valid email address.");
          // $("#<%=txtEmail.ClientID %>").focus();
          return false;
        }
      }

      if(pass != passagain) {
        $('#inputPasswordAgain').addClass('is-invalid');
        return false;
      }
   
    });
  }
}
